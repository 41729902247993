<template>
  <article
      :class="deletedCartIds ? 'cart-table-disabled position-relative' : ''"
      class="cart-table-border px-3 pb-3 pt-md-3 pt-4"
  >
    <div class="cart-table-grid ">
      <div class="d-flex text-right ">
        <div class="cart-table-img ml-3 border-radius10">
          <router-link
          class="border-radius10"
              :to="
              cartItem.variety != null
                ? {
                    name: 'product.detail',
                    params: {
                      id: cartItem.variety.product.id,
                      slug: cartItem.variety.product.slug,
                    },
                  }
                : ''
            "
          >
            <img
                v-if="mainImage != ''"
                :src="getImage(mainImage[0], 'md')"
                alt=""
                class=" d-block border-radius10"
            />
            <div v-else class="w-100 h-100"></div>
          </router-link>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <router-link
              :to="
              cartItem.variety != null
                ? {
                    name: 'product.detail',
                    params: {
                      id: cartItem.variety.product.id,
                      slug: cartItem.variety.product.slug,
                    },
                  }
                : ''
            "
              class="mb-1 fontsize-medium font-900 weight-bold text-color-444 "
          >
            {{
              cartItem.variety
                  ? cartItem.variety.product.title.substr(0, 100)
                  : ""
            }}
            {{
              cartItem.variety && cartItem.variety.product.title.length > 100
                  ? "..."
                  : ""
            }}
          </router-link>

          <div class="mb-2">
            <span
                v-if="cartItem.variety && cartItem.variety.color_id != null"
                class="fontsize-small"
            >
              <span class="weight-bold text-color-666">رنگ :</span>
              <span
                  class="color-tik px-1 mx-1"
                  :style="'background-color:' + cartItem.variety.color.code"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check"
                    viewBox="0 0 16 16"
                >
                  <path
                      fill="#fff"
                      d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                  />
                </svg>
              </span>
              <span class="text-color-999">{{
                  cartItem.variety.color.name
                }}</span>
            </span>

            <span
                v-if="
                cartItem.variety &&
                  cartItem.variety.color_id != null &&
                  cartItem.variety.attributes != ''
              "
                class="text-color-999 mx-2"
            >|</span
            >
            <span
                v-if="cartItem.variety && cartItem.variety.attributes != ''"
                class="fontsize-small"
            >
              <span
                  v-for="(attr, index) in cartItem.variety.attributes"
                  :key="attr.id"
              >
                <span class="text-color-666 weight-bold">
                  {{ attr.label }} :
                </span>
                <span class="text-color-444">{{ attr.pivot.value }}</span>
                <span
                    v-if="index != cartItem.variety.attributes.length - 1"
                    class="text-color-999 mx-2 fontsize17"
                >|</span
                >
              </span>
            </span>
          </div>
          <div class="fontsize-small text-color-444">
            <span class="ml-1">کدمحصول : </span>
            <span v-if="isFakeVariety(cartItem.variety)">
              {{ cartItem.variety ? cartItem.variety.product.id : "" }}
            </span>
            <span v-else>
              {{ cartItem.variety ? cartItem.variety.product.id : "" }} - {{ cartItem.variety ? cartItem.variety.id : "" }}
            </span>
          </div>
        </div>
      </div>
      <div class="fontsize14 text-lg-center align-self-center  mb-lg-0 mb-3">
        <span class="d-lg-none ml-2">قیمت واحد :</span>
        <span class="line-through ml-1" style="opacity: 0.8;" v-if="cartItem && cartItem.discount_price">
          {{ (cartItem.discount_price + cartItem.price) | price }}
        </span>
        <span class="fontsize-medium text-color-444">{{
            (cartItem ? cartItem.price : 0) | price
          }}</span>
        <span class="fontsize-small text-color-999 pr-1">تومان</span>
      </div>

      <div
          class="fontsize-medium align-self-center text-lg-center d-flex align-items-center justify-content-lg-center justify-content-between text-right mb-lg-0 mb-3"
      >
        <div>
          <span class="d-lg-none ml-2"> تعداد:</span>
          <CartQuantity @quantityChanged="$emit('quantityChanged')" v-model="cartItem"/>
        </div>

        <button
            @click="deleteItem()"
            :disabled="disabled"
            type="button"
            class="bg-none d-lg-none d-block my-3"
        >
          <svg
              width="25"
              height="25  "
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
                fill="#ee1212"
            />
          </svg>
        </button>
      </div>
      <button
          @click="deleteItem()"
          :disabled="disabled"
          type="button"
          class="bg-none d-lg-block d-none my-3"
      >
        <svg
            width="25"
            height="25  "
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
              fill="#ee1212"
          />
        </svg>
      </button>
    </div>
    <div
        class="fontsize14 d-flex align-items-bottom text-danger mt-2"
        v-if="warning && warning.type != 'unavailable'"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="20"
          height="20"
          viewBox="0 0 172 172"
          style=" fill:#000000;"
      >
        <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
        >
          <path d="M0,172v-172h172v172z" fill="none"></path>
          <g fill="#dc3545">
            <path
                d="M167.15992,142.40568l-72.08864,-126.7812c-1.88856,-3.3196 -5.27696,-5.30448 -9.07128,-5.30448c-3.79432,0 -7.18272,1.98488 -9.07128,5.30448l-72.08864,126.7812c-1.89544,3.33336 -1.8748,7.30656 0.05504,10.62272c1.89888,3.26456 5.26664,5.2116 9.01624,5.2116h144.17728c3.7496,0 7.11736,-1.94704 9.01624,-5.2116c1.92984,-3.31616 1.95048,-7.28592 0.05504,-10.62272zM92.88,134.16c0,1.89888 -1.53424,3.44 -3.43312,3.44c-2.10528,0 -4.78848,0 -6.89376,0c-1.89888,0 -3.43312,-1.54112 -3.43312,-3.44v-6.88c0,-1.89888 1.53424,-3.44 3.43312,-3.44c2.10528,0 4.78848,0 6.89032,0c1.90232,0 3.43656,1.54112 3.43656,3.44zM92.88,110.08c0,1.89888 -1.54112,3.44 -3.44,3.44c-2.10528,0 -4.78848,0 -6.89032,0c-1.90232,0 -3.42968,-1.54112 -3.42968,-3.44v-48.16c0,-1.89888 1.53424,-3.44 3.43312,-3.44c2.10528,0 4.78848,0 6.89032,0c1.90232,0 3.43656,1.54112 3.43656,3.44z"
            ></path>
          </g>
        </g>
      </svg>
      <span class="pr-1 pt-1" v-if="warning.type == 'price'">
        قیمت این محصول {{ warning.diff_price | price }} تومان
        {{ 0 > warning.diff_price ? "کاهش" : "افزایش" }} یافت
      </span>
      <span class="pr-1 pt-1" v-else-if="warning.type == 'quantity'">
        موجودی این محصول کاهش یافت
      </span>
    </div>
    <div v-if="deletedCartIds" class="position-absolute cart-notif">
      <h5 class="text-danger fontsize17 weight-bold">
        موجودی این محصول به اتمام رسیده است .
      </h5>
    </div>
  </article>
</template>
<script>
import CartQuantity from "@/parts/Front/components/cart/CartQuantity";
import product from "@/parts/Front/components/productDetail/classes/product";
import {getImage} from '@/libraries/functions'

export default {
  name: "CartItem",
  components: {
    CartQuantity,
  },
  props: {
    value: Object,
    index: Number,
    warning: null,
    deletedCartIds: null,
  },
  data() {
    return {
      disabled: false,
      cartItem: this.value,
    };
  },
  watch: {
    cartItem(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.cartItem = newVal;
    },
  },
  computed: {
    mainImage() {
      let images = product.getImagesForVariety(this.cartItem.variety, ['size'])

      if (images != "") {
        return images;
      } else {
        return this.cartItem.variety.product.images;
      }
    },
  },
  methods: {
    getImage,
    isFakeVariety: product.isFakeVariety,
    deleteItem() {
      let item = {
        ...this.cartItem,
      };
      window
          .swal({
            title: "محصول از سبد خرید حذف شود؟",
            text: "این عملیات غیر قابل بازگشت خواهد بود",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله حذف کن"],
          })
          .then(async (willDelete) => {
            if (willDelete) {
              try {
                let response = await this.$store.dispatch(
                    "front/deleteCart",
                    item
                );
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              } catch (error) {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              }
            }
          })
          .catch(() => {
          });
    },
  },
};
</script>
<style scoped>
a:hover {
  color: unset !important;
}

.cart-notif {
  bottom: 0;
  left: 44%;
  z-index: 2;
}

.cart-table-disabled {
  user-select: none;
}

.cart-table-disabled::after {
  content: "";
  position: absolute;
  background-color: #fff;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  left: 0;
}
</style>
