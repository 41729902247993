<template>
  <section
    class="payment-method bg-white box-shaddow20 container mb-5 border-radius20"
  >
    <div class="d-flex  text-right p-3 border-bottom">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6389 14.3958H17.5906C16.1042 14.3949 14.8994 13.1909 14.8985 11.7045C14.8985 10.218 16.1042 9.01413 17.5906 9.01321H21.6389"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.0486 11.6429H17.7369"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.74766 3H16.3911C19.2892 3 21.6388 5.34951 21.6388 8.24766V15.4247C21.6388 18.3229 19.2892 20.6724 16.3911 20.6724H7.74766C4.84951 20.6724 2.5 18.3229 2.5 15.4247V8.24766C2.5 5.34951 4.84951 3 7.74766 3Z"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.03561 7.53817H12.4346"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h6 class="fontsize-large text-color-444 mt-1 mr-1 weight-bold">
        انتخاب شیوه پرداخت
      </h6>
    </div>
    <div class="row p-3">
      <section
        class="internet-payment col-md-6 col-12 p-3 bg-color-fa border border-radius15"
      >

       <div
          @click="(e) => selectPayMethod(e, true)"
          :style="requestingWallet ? 'opacity: 0.6' : ''"
          class="cursor-pointer d-flex align-items-center mb-3"
        >
          <div class="align-self-center order-type ml-2 d-inline-block">
            <label class="address-checked-container">
              <input
                ref="radioWallet"
                type="radio"
                :checked="payWallet"
                name="pay_method"
              />
              <span class="address-checkmark"></span>
            </label>
          </div>

          <svg
            class="mr-3"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7689 8.3818H22C22 4.98459 19.9644 3 16.5156 3H7.48444C4.03556 3 2 4.98459 2 8.33847V15.6615C2 19.0154 4.03556 21 7.48444 21H16.5156C19.9644 21 22 19.0154 22 15.6615V15.3495H17.7689C15.8052 15.3495 14.2133 13.7975 14.2133 11.883C14.2133 9.96849 15.8052 8.41647 17.7689 8.41647V8.3818ZM17.7689 9.87241H21.2533C21.6657 9.87241 22 10.1983 22 10.6004V13.131C21.9952 13.5311 21.6637 13.8543 21.2533 13.8589H17.8489C16.8548 13.872 15.9855 13.2084 15.76 12.2643C15.6471 11.6783 15.8056 11.0736 16.1931 10.6122C16.5805 10.1509 17.1573 9.88007 17.7689 9.87241ZM17.92 12.533H18.2489C18.6711 12.533 19.0133 12.1993 19.0133 11.7877C19.0133 11.3761 18.6711 11.0424 18.2489 11.0424H17.92C17.7181 11.0401 17.5236 11.1166 17.38 11.255C17.2364 11.3934 17.1555 11.5821 17.1556 11.779C17.1555 12.1921 17.4964 12.5282 17.92 12.533ZM6.73778 8.3818H12.3822C12.8044 8.3818 13.1467 8.04812 13.1467 7.63649C13.1467 7.22487 12.8044 6.89119 12.3822 6.89119H6.73778C6.31903 6.89116 5.9782 7.2196 5.97333 7.62783C5.97331 8.04087 6.31415 8.37705 6.73778 8.3818Z"
              fill="#666"
            />
          </svg>
          <h6 class="mr-1 mb-0 text-color-444 fontsize-medium">
            <span>پرداخت از کیف پول</span>
            <span v-if="user && user.wallet">
              (موجودی فعلی: {{ user.wallet.balance | price }} تومان)</span
            >
          </h6>
        </div>
        <div
          @click="(e) => selectPayMethod(e, false)"
          class="cursor-pointer d-flex align-items-center mb-1"
        >
          <div class="align-self-center order-type ml-2 d-inline-block">
            <label class="address-checked-container">
              <input
                ref="radioGateway"
                type="radio"
                :checked="true"
                name="pay_method"
              />
              <span class="address-checkmark"></span>
            </label>
          </div>
          <img
            :src="require('@/assets/images/money.png')"
            class="wallet-img mr-3"
            :alt="'wallet'"
          />
          <h6 class="mr-1 mb-0 text-color-444 fontsize-medium">
            پرداخت اینترنتی
          </h6>
        </div>
        <p class="text-color-666 fontsize-small">
          پرداخت با کلیه کارت های اعتباری شرکت
        </p>
        <Gateways
          class="mt-4"
          @gatewaySelected="onGatewayClicked"
          :selected-gateway="selectedGateway"
        />
      </section>
       <section class="col-md-6 col-12 px-sm-3 px-0">
        <div
          class="p-3 order-type bg-color-fa fontsize-medium mb-3 mt-md-0 mt-3 text-justify border-radius15"
        >
          <span
            >نوع سفارش:
            <span
              @click="(e) => selectReserved(e, false)"
              class="cursor-pointer ml-3 mr-3"
            >
              <div class="align-self-center ml-2 d-inline-block">
                <label class="address-checked-container">
                  <input
                    type="radio"
                    class="ml-1"
                    name="reserved"
                    :checked="!reserved"
                  />
                  <span class="address-checkmark"></span>
                </label>
              </div>
              <span class="mr-3"> عادی </span>
            </span>
          </span>
        </div>
        <div
          class="p-3 bg-color-fa mb-3 mt-md-0 mt-3 text-justify border-radius15"
        >
          <span class="text-color-666 fontsize-small ">این سفارش به اسم </span>
          <span class="weight-bold fontsize13 text-color-444">
            {{ selectedAddress.first_name + " " + selectedAddress.last_name }}
          </span>
          <span class="text-color-666 fontsize-small ">به آدرس </span>
          <span class="weight-bold fontsize13 text-color-444"
            >{{ selectedAddress.address }}
          </span>
          <span class="text-color-666 fontsize-small ">به شماره تماس </span>
          <span class="weight-bold fontsize13 text-color-444"
            >{{ selectedAddress.mobile }}
          </span>
          <span class="text-color-666 fontsize-small "
            >ارسال و تحویل می گردد
          </span>
        </div>
        <div class="p-3 bg-color-fa border border-radius15">
          <div class="border-bottom">
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"
                >مجموع قیمت های کالا:
              </span>
              <span>
                <span class="fontsize-medium ml-1">{{
                  totalPrice | price
                }}</span>
                <span class="fontsize-small text-color-999">تومان</span>
              </span>
            </div>
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"> تخفیف: </span>
              <span>
                <span class="ml-4" v-if="totalSaving === 0">―</span>
                <template v-else>
                  <span class=" fontsize-medium ml-1 weight-bold ">{{
                    totalSaving | price
                  }}</span>
                  <span class="fontsize-small ">تومان</span>
                </template>
              </span>
            </div>
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"> هزینه ارسال: </span>
              <span>
                <span class="fontsize-medium mr-1" v-if="shippingPrice === 0">
                  {{
                    selectedShipping.id == 5 ? "رایگان" : "پس کرایه (درب منزل)"
                  }}
                </span>
                <template v-else>
                  <span class="fontsize-medium ml-1">{{
                    shippingPrice | price
                  }}</span>
                  <span class="fontsize-small text-color-999">تومان</span>
                </template>
              </span>
            </div>
            <div
              v-if="discount && discount.discount > 0"
              class="text-color-444 d-flex justify-content-between mb-2"
            >
              <span class="fontsize-medium weight-bold text-color-discount">
                تخفیف از کوپن:
              </span>
              <span class="text-color-discount">
                <span class=" fontsize-medium ml-1 weight-bold ">{{
                  discount.discount | price
                }}</span>
                <span class="fontsize-small ">تومان</span>
              </span>
            </div>
          </div>
          <div class="text-color-444 d-flex justify-content-between mt-2">
            <span class="fontsize-medium weight-bold"> پرداخت نهایی: </span>
            <span class="text-color-theme">
              <span class=" weight-bold fontsize-large ml-1">{{
                (finalTotal - totalSaving) | price
              }}</span>
              <span class="fontsize-medium">تومان</span>
            </span>
          </div>
        </div>
      
      </section>
    </div>
    <div
      class="d-flex justify-content-between align-items-baseline py-3 px-sm-3 px-0"
    >
      <button
        id="prev-btn-step-3"
        class="continue-buy-process py-2 w-25 ml-2 fontsize-medium text-center border-radius10"
        @click="prevPage"
      >
        <ZArrow />
        مرحله قبل
      </button>
      <button
        class="continue-buy-process py-2  w-25 text-center fontsize-medium border-radius10"
        :disabled="
          waitingForPayStatus != 'init' && waitingForPayStatus != 'error'
        "
        @click="pay"
      >
        <template v-if="waitingForPayStatus == 'init'">پرداخت</template>
        <template v-else-if="waitingForPayStatus == 'loading'">پرداخت</template>
        <template v-else-if="waitingForPayStatus == 'redirecting'"
          >درحال انتقال به درگاه</template
        >
        <template v-else-if="waitingForPayStatus == 'error'"
          >تلاش مجدد</template
        >
      </button>
       
    </div>
  </section>
</template>
<script>
import Gateways from "../shared/Gateways";
import ZArrow from "@/parts/Front/components/shared/ZArrow";
export default {
  name: "CartStep3",
  components: { ZArrow, Gateways },
  data() {
    return {
      requestingWallet: false,
    };
  },
  props: {
    gateways: Array,
    selectedGateway: Object,
    selectedAddress: Object,
    discount: Number,
    shippingPrice: Number,
    total: Number,
    totalSaving: Number,
    totalPrice: Number,
    payWallet: Boolean,
    reserved: Boolean,
    waitingForPayStatus: String,
    finalTotal: Number,
    reservations: Array,
    selectedShipping: Object,
  },
  mounted() {
    // Select default
    if (this.gateways && this.gateways.length && !this.selectedGateway) {
      this.$emit("gatewaySelected", this.gateways[0]);
    }
  },
  computed: {
    user() {
      return this.homeData.user.user;
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  methods: {
    nextPage() {
      this.$emit("next-page");
    },
    prevPage() {
      this.$emit("prev-page");
    },
    onGatewayClicked(gateway) {
      this.$emit("gatewaySelected", gateway);
    },
    pay() {
      this.$emit("pay");
    },
    async selectPayMethod(e, wallet) {
      if (wallet) {
        this.$emit("payMethodSelected", true);
        console.log(this.user);
        console.log(this.homeData);
        if (this.user.wallet.balance < this.finalTotal) {
          this.requestingWallet = true;
          try {
            const response = await this.$axios.get("user/get-balance");
            this.user.wallet.balance = response.data;
          } catch (er) {
            this.$root.notify(er);
          } finally {
            this.requestingWallet = false;
          }
        }
      } else {
        this.$nextTick(() => {
          this.$emit("payMethodSelected", false);
        });
      }
    },

    selectReserved(e, reserved) {
      this.$emit("reserveSelected", reserved);
    },
  },
};
</script>
<style scoped>
.wallet-img {
  width: 27px;
  height: 24px;
}
.order-type .address-checkmark,
.order-type .address-checkmark::after {
  border-radius: 25px;
}

#prev-btn-step-3 {
  background: none;
  border: 1px solid #000;
  color: black !important;
}
</style>
