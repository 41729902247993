<template>
  <section class="address-lists p-3 bg-white   box-shaddow20">
    <h4 class="text-center ">لیست آدرس ها</h4>
    <LineGrow open-width="90%" />
    <div
      class="d-flex flex-wrap  justify-content-between align-items-baseline border-bottom pb-3"
    >
      <button
        class="bg-color-theme2 py-2 px-sm-4 px-3  "
        data-toggle="modal"
        data-target="#AddAddress1"
        @click="newAddress"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 8.3273V15.6537"
            stroke="#121314"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.6667 11.9905H8.33333"
            stroke="#121314"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z"
            stroke="#121314"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="text-color-theme mr-1 fontsize14 weight-bold"
          >افزودن آدرس جدید</span
        >
      </button>
    </div>
    <div>
      <div class="address-list-grid py-3 text-center border-bottom">
        <span class="text-color-444 fontsize14 weight-bold">شناسه</span>
        <span class="text-color-444 fontsize14 weight-bold">تحویل گیرنده</span>
        <span class="text-color-444 fontsize14 weight-bold">آدرس</span>
        <span class="text-color-444 fontsize14 weight-bold">عملیات</span>
      </div>
      <div class="py-3">
        <div
          v-if="addresses == null || addresses == ''"
          class="text-center py-5"
        >
          <svg
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9999 2H12.0333C16.7197 2.01842 20.5183 5.84197 20.4999 10.5223V10.6153C20.4366 13.96 18.4258 16.7356 16.7504 18.4746C16.2736 18.9718 15.7661 19.4489 15.2427 19.8944C14.9292 20.1619 14.4586 20.1251 14.1908 19.812C13.923 19.498 13.9607 19.028 14.2742 18.7605C14.7589 18.3475 15.2304 17.9037 15.6739 17.4416C17.1649 15.8946 18.9527 13.4487 19.0071 10.5881C19.022 6.65842 15.8916 3.50574 12.0272 3.48996H11.9999C8.14862 3.48996 5.00768 6.61194 4.99276 10.4609C5.05861 12.3622 5.70138 14.1696 6.85256 15.6859C8.19604 17.4749 10.2341 19.3691 12.1712 20.6302C12.5171 20.8547 12.6146 21.316 12.3889 21.6615C12.2467 21.8807 12.007 22 11.7628 22C11.6232 22 11.4827 21.9614 11.3563 21.879C9.28224 20.5293 7.0993 18.5 5.66011 16.5839C4.32453 14.8238 3.57727 12.7235 3.5 10.5127C3.51844 5.78585 7.32848 2 11.9999 2ZM10.2552 10.6141C10.2552 11.5727 11.0367 12.354 11.9973 12.354C12.9579 12.354 13.7394 11.5727 13.7394 10.6141C13.7394 9.65474 12.9579 8.87337 11.9973 8.87337C11.5855 8.87337 11.2509 8.54012 11.2509 8.12883C11.2509 7.71665 11.5855 7.38341 11.9973 7.38341C13.7807 7.38341 15.2322 8.83215 15.2322 10.6141C15.2322 12.3952 13.7807 13.844 11.9973 13.844C10.2139 13.844 8.76241 12.3952 8.76241 10.6141C8.76241 10.202 9.09696 9.86872 9.50879 9.86872C9.92061 9.86872 10.2552 10.202 10.2552 10.6141Z"
              fill="#200E32"
            />
          </svg>
          <h5 class="weight-bold">آدرسی وجود ندارد</h5>
        </div>
        <template v-else>
          <article
            v-for="address in addresses"
            :key="address.id"
            class="address-list-grid py-3 px-2 mb-2 text-center fontsize-mini text-color-666   border"
          >
            <span> {{ address.id }}</span>
            <span>{{ address.first_name }} {{ address.last_name }}</span>
            <span>
              {{
                address.city && address.city.province
                  ? address.city.province.name + " - "
                  : ""
              }}
              {{ address.city ? address.city.name + " - " : "" }}
              {{ address.address }}
            </span>
            <span>
              <button class="ml-2 bg-none" @click="editAddress(address)">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.3764 20.0279L18.1628 8.66544C18.6403 8.0527 18.8101 7.3443 18.6509 6.62299C18.513 5.96726 18.1097 5.34377 17.5049 4.87078L16.0299 3.69906C14.7459 2.67784 13.1541 2.78534 12.2415 3.95706L11.2546 5.23735C11.1273 5.39752 11.1591 5.63401 11.3183 5.76301C11.3183 5.76301 13.812 7.76246 13.8651 7.80546C14.0349 7.96671 14.1622 8.1817 14.1941 8.43969C14.2471 8.94493 13.8969 9.41792 13.377 9.48242C13.1329 9.51467 12.8994 9.43942 12.7297 9.29967L10.1086 7.21422C9.98126 7.11855 9.79025 7.13898 9.68413 7.26797L3.45514 15.3303C3.0519 15.8355 2.91395 16.4912 3.0519 17.1255L3.84777 20.5761C3.89021 20.7589 4.04939 20.8879 4.24039 20.8879L7.74222 20.8449C8.37891 20.8341 8.97316 20.5439 9.3764 20.0279ZM14.2797 18.9533H19.9898C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533Z"
                    fill="#121314"
                  />
                </svg>
              </button>
              <button
                class="bg-none"
                @click="deleteAddress(address.id)"
                :disabled="disabled"
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
                    fill="#ee1212"
                  />
                </svg>
              </button>
            </span>
          </article>
        </template>
      </div>
    </div>
    <address-modal
      @new-address="addNewAddress"
      :mode="mode"
      v-model="address"
    ></address-modal>
  </section>
</template>
<script>
import AddressModal from "@/parts/Front/components/AddressModal";
import LineGrow from "@/parts/Front/components/shared/LineGrow";
export default {
  components: {
    LineGrow,
    AddressModal,
  },
  props: { tab: { Number } },
  data() {
    return {
      mode: "create",
      disabled: false,
      address: {},
    };
  },
  created() {
    if (this.$store.getters["front/getProvincesData"] == null) {
      this.$store.dispatch("front/getProvincesDataFromServer");
    }
  },
  mounted() {
    if (this.provinces == null) {
      this.$store.dispatch("front/getProvincesDataFromServer");
    }
  },
  computed: {
    addresses() {
      return this.$store.getters["front/getUserAddress"]
    },
    provinces() {
      return this.$store.getters["front/getProvincesData"];
    },
  },
  methods: {
    addNewAddress(newAddress) {
      if (this.mode == "create") {
        this.$store.commit("front/newAddress", newAddress);
      } else {
        this.$store.commit("front/editAddress", newAddress);
      }
    },
    newAddress() {
      this.address = {
        province: null,
        city: null,
        first_name: "",
        last_name: "",
        postal_code: "",
        address: "",
        mobile: "",
      };
      this.mode = "create";
    },
    async deleteAddress(id) {
      this.disabled = true;
      try {
        const response = await this.$store.dispatch("front/deleteAddress", {
          id,
        });
        if (response) {
          this.$root.notify(response.data.message, "success");
          this.$store.commit("front/deleteAddress", id);
        }
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
    async editAddress(item) {
      let address = { ...item };
      address.province = address.city.province;
      this.address = address;
      this.mode = "edit";
      window.$("#AddAddress1").modal("show");
    },
  },
};
</script>
<style scoped>
.address-list-grid {
  display: grid;
  grid-template-columns: 50px 1fr 2fr 60px;
  grid-gap: 15px;
}
</style>
